import axios from 'axios';
import { defineStore } from 'pinia';
import { clearFromXSS, clearComments } from '../helpers/security';
import { idsToKeys } from '../helpers/data';

interface ElectionsState {
    all: Array<any>;
    running: Array<any>;
    past: Array<any>;
    titles: Array<any>;
}

interface ServerInfo {
    Description: string;
    Version: string;
    JoinUrl: string;
};

interface WebPluginInfo {
    TypeName: string;
    FontAwesomeIcon: string;
    PluginIndexUrl: string;
    MenuTitle: string;
}

interface State {
    username: string | null;
    authtoken: string | null;
    authtokentype: string | null;
    elections: ElectionsState;
    serverInfo: ServerInfo;
    profiling: Array<any>;
    webPlugins: Array<WebPluginInfo>;
    electionsLoaded: boolean;
}

export const useMainStore = defineStore("main", {
    state(): State {
        return {
            username: localStorage.getItem('username'),
            authtoken: localStorage.getItem('authtoken'),
            authtokentype: localStorage.getItem('authtokentype'),
            elections: { all: [], running: [], past: [], titles: [] },
            serverInfo: { Description: 'loading...', Version: 'loading...', JoinUrl: 'loading...' },
            profiling: [],
            webPlugins: [],
            electionsLoaded: false,
        }
    },
    actions: {
        auth() {
            const params = new URLSearchParams(location.search);

            // update username & authtoken

            var username = params.get('username');
            var authToken = params.get('authtoken');
            var authtokentype = params.get('authtokentype') || 'slg';

            if (username && authToken) {
                localStorage.setItem('username', username);
                localStorage.setItem('authtoken', authToken);
                localStorage.setItem('authtokentype', authtokentype);

                this.username = username;
                this.authtoken = authToken;
                this.authtokentype = authtokentype;

                // Update visible URL in browser to one without params.
                this.$router.replace({ path: location.pathname });
            }

            axios.interceptors.request.use(config => {
                this.globalProperties.$Progress.start();
                config.headers["X-Auth-Token"] = this.authtoken;
                config.headers["X-Auth-Token-Type"] = this.authtokentype;
                return config;
            });
        },
        async fetchServerInfo(): Promise<void> {
            const { data: res } = await axios.get('/info', { baseURL: (window as any).serverUrl });
            if (!res) return;

            this.serverInfo = res;
        },
        async fetchElections(): Promise<void> {
            const { data: runningRaw } = await axios.get('/elections?returnActive=true');
            const { data: pastRaw } = await axios.get('/elections?returnActive=false');
            const { data: titlesRaw } = await axios.get('/elections/titles');

            if (!runningRaw || !pastRaw || !titlesRaw) return;

            const running = clearFromXSS(runningRaw);
            const past = clearFromXSS(pastRaw);
            const titles = clearFromXSS(titlesRaw);

            const all = idsToKeys(clearComments([...running, ...past]));

            let val = { running, past, titles, all };

            this.elections = val;
            this.electionsLoaded = true;
        },
        async fetchProfilingResults(): Promise<void> {
            let { data: res } = await axios.get('/profiling-results');
            if (!res) return;

            this.profiling = res;
        },
        async fetchWebPlugins(): Promise<void> {
            let { data: res } = await axios.get('/plugins/web');
            if (!res) return;

            this.webPlugins = res;
        }
    }
});
